export default class ProducaoSettings {
  production = true;
  citiesWhitelist = [
    {
      name: 'Chapecó - SC',
      identity: 'chapecosc',
      connection: 'OneDocChapeco',
    },
    {
      name: 'Barretos - SP',
      identity: 'barretossp',
      connection: 'BarretosSP',
    },
    {
      name: 'Xangri-lá - RS',
      identity: 'xangrilars',
      connection: 'Xangrila',
    },
    {
      name: 'Modelo - AD',
      identity: 'modeload',
      connection: 'ModeloAD',
    },
    {
      name: 'Curitibanos - SC',
      identity: 'curitibanossc',
      connection: 'CuritibanosSC',
    },
    {
      name: 'Cascavel - PR',
      identity: 'cascavelpr',
      connection: 'CascavelPR',
    },
    {
      name: 'Mogi das Cruzes - SP',
      identity: 'mogidascruzessp',
      connection: 'MogiDasCruzesSP',
    },
    {
      name: 'Mafra - SC',
      identity: 'mafrasc',
      connection: 'MafraSC',
    },
    {
      name: "Santa Bárbara D'Oeste - SP",
      identity: 'santabarbaradoestesp',
      connection: 'SantaBarbaraDOesteSP',
    },
    {
      name: 'Itajaí - SC',
      identity: 'itajaisc',
      connection: 'ItajaiSC',
    },
    {
      name: 'Sorocaba - SP',
      identity: 'sorocabasp',
      connection: 'SorocabaSP',
    },
    {
      name: 'Massaranduba - SC',
      identity: 'massarandubasc',
      connection: 'MassarandubaSC',
    },
    {
      name: 'Lages - SC',
      identity: 'lagessc',
      connection: 'LagesSC',
    },
    {
      name: 'Santana de Parnaíba - SP',
      identity: 'santanadeparnaibasp',
      connection: 'SantanadeparnaibaSP',
    },
    {
      name: 'Presidente Prudente - SP',
      identity: 'presidenteprudentesp',
      connection: 'OneDocPresidentePrudente',
    },
    {
      name: 'Bauru - SP',
      identity: 'baurusp',
      connection: 'BauruSP',
    },
    {
      name: 'Jaboticabal - SP',
      identity: 'jaboticabalsp',
      connection: 'JaboticabalSP',
    },
    {
      name: 'Caçador - SC',
      identity: 'cacadorsc',
      connection: 'CacadorSC',
    },
    {
      name: 'IPC - PR',
      identity: 'ipcpr',
      connection: 'IpcPR',
    },
    {
      name: 'Campo Mourão - PR',
      identity: 'campomouraopr',
      connection: 'CampomouraoPR',
    },
    {
      name: 'Poc - AD',
      identity: 'pocad',
      connection: 'PocAD',
    },
    {
      name: 'Urupema - SC',
      identity: 'urupemasc',
      connection: 'UrupemaSC',
    },
    {
      name: 'Valparaíso - GO',
      identity: 'valparaisogo',
      connection: 'ValparaisoGO',
    },
    {
      name: 'Demo - AD',
      identity: 'demoad',
      connection: 'DemoAD',
    },
    {
      name: 'Varginha - MG',
      identity: 'varginhamg',
      connection: 'VarginhaMG',
    },
    {
      name: 'Bertioga - SP',
      identity: 'bertiogasp',
      connection: 'BertiogaSP',
    },
    {
      name: 'Patos de Minas - MG',
      identity: 'patosdeminasmg',
      connection: 'PatosDeMinasMG',
    },
    {
      name: 'Implantação - AD',
      identity: 'implantacaoad',
      connection: 'ImplantacaoAD',
    },
    {
      name: 'Aprovadesk - AD',
      identity: 'aprovadeskad',
      connection: 'AprovadeskAD',
    },
    {
      name: 'Criciúma - SC',
      identity: 'criciumasc',
      connection: 'CriciumaSC',
    },
    {
      name: 'Gaspar - SC',
      identity: 'gasparsc',
      connection: 'GasparSC',
    },
    {
      name: 'Indaial - SC',
      identity: 'indaialsc',
      connection: 'IndaialSC',
    },
    {
      name: 'CIMVI - SC',
      identity: 'cimvisc',
      connection: 'CimviSC',
    },
    {
      name: 'Cajamar - SP',
      identity: 'cajamarsp',
      connection: 'CajamarSP',
    },
    {
      name: 'Marechal Cândido Rondon - PR',
      identity: 'marechalcandidorondonpr',
      connection: 'IpmMarechalCandidoRondon',
    },
    {
      name: 'Franca - SP',
      identity: 'francasp',
      connection: 'FrancaSP',
    },
    {
      name: 'Três Lagoas - MS',
      identity: 'treslagoasms',
      connection: 'TreslagoasMS',
    },
    {
      name: 'São José do Rio Preto - SP',
      identity: 'saojosedoriopretosp',
      connection: 'SaoJoseDoRioPretoSP',
    },
    {
      name: 'Florianópolis - SC',
      identity: 'florianopolissc',
      connection: 'FlorianopolisSC',
    },
    {
      name: 'Condomínios - AD',
      identity: 'condominiosad',
      connection: 'CondominiosAD',
    },
    {
      name: 'Chapecó RH - SC',
      identity: 'chapeco-rhsc',
      connection: 'ChapecoRH',
    },
    {
      name: 'Conselheiro Lafaiete - MG',
      identity: 'conselheirolafaietemg',
      connection: 'ConselheirolafaieteMG',
    },
    {
      name: 'Navegantes - SC',
      identity: 'navegantessc',
      connection: 'NavegantesSC',
    },
    {
      name: 'Goiatuba - GO',
      identity: 'goiatubago',
      connection: 'GoiatubaGO',
    },
    {
      name: 'Santa Luzia - MG',
      identity: 'santaluziamg',
      connection: 'SantaLuziaMG',
    },
    {
      name: 'Guararema - SP',
      identity: 'guararemasp',
      connection: 'GuararemaSP',
    },
    {
      name: 'João Pessoa - PB',
      identity: 'joaopessoapb',
      connection: 'JoaoPessoaPB',
    },
    {
      name: 'Joaçaba - SC',
      identity: 'joacabasc',
      connection: 'JoacabaSC',
    },
    {
      name: 'Prudentópolis - PR',
      identity: 'prudentopolispr',
      connection: 'PrudentopolisPR',
    },
    {
      name: 'Canoinhas - SC',
      identity: 'canoinhassc',
      connection: 'CanoinhasSC',
    },
    {
      name: 'Lençóis Paulista - SP',
      identity: 'lencoispaulistasp',
      connection: 'LencoispaulistaSP',
    },
    {
      name: 'Santa Cruz do Sul - RS',
      identity: 'santacruzdosulrs',
      connection: 'SantacruzdosulRS',
    },
    {
      name: 'Palmeiras de Goiás - GO',
      identity: 'palmeirasdegoiasgo',
      connection: 'PalmeirasdegoiasGO',
    },
    {
      name: 'Palmeira - SC',
      identity: 'palmeirasc',
      connection: 'PalmeiraSC',
    },
    {
      name: 'Lavras - MG',
      identity: 'lavrasmg',
      connection: 'LavrasMG',
    },
    {
      name: 'Procon Chapecó - SC',
      identity: 'proconchapecosc',
      connection: 'ProconchapecoSC',
    },
    {
      name: 'Lagoa Santa - MG',
      identity: 'lagoasantamg',
      connection: 'LagoasantaMG',
    },
    {
      name: 'Volta Redonda - RJ',
      identity: 'voltaredondarj',
      connection: 'VoltaredondaRJ',
    },
    {
      name: 'Altaneira - CE',
      identity: 'altaneirace',
      connection: 'AltaneiraCE',
    },
    {
      name: 'Ouro Verde - SC',
      identity: 'ouroverdesc',
      connection: 'OuroVerdeSC',
    },
    {
      name: 'Câmara Municipal de Caçaador - SC',
      identity: 'cmcacadorsc',
      connection: 'CamaraMunicipalDeCacadorSC',
    },
    {
      name: 'Irani - SC',
      identity: 'iranisc',
      connection: 'IraniSC',
    },
    {
      name: 'Homologação - AD',
      identity: 'homologacaoad',
      connection: 'HomologacaoAD',
    },
    {
      name: 'CM Florianópolis - SC',
      identity: 'cmflorianopolissc',
      connection: 'CMFlorianopolisSC',
    },
    {
      name: 'Abelardo Luz - SC',
      identity: 'abelardoluzsc',
      connection: 'AbelardoLuzSC',
    },
    {
      name: 'Companhia Águas De Itapema - SC',
      identity: 'companhiaaguasdeitapemasc',
      connection: 'CompanhiaAguasDeItapemaSC',
    },
    {
      name: 'Campo Belo - MG',
      identity: 'campobelomg',
      connection: 'CampoBeloMG',
    },
    {
      name: 'Processo Seletivo - AD',
      identity: 'processoseletivoad',
      connection: 'ProcessoSeletivoAD',
    },
    {
      name: 'Muriaé - MG',
      identity: 'muriaemg',
      connection: 'MuriaeMG',
    },
    {
      name: 'Timbó Grande - SC',
      identity: 'timbograndesc',
      connection: 'TimboGrandeSC',
    },
    {
      name: 'Cabreúva - SP',
      identity: 'cabreuvasp',
      connection: 'CabreuvaSP',
    },
    {
      name: 'Dilermando de Aguiar - RS',
      identity: 'dilermandodeaguiarrs',
      connection: 'DilermandoDeAguiarRS',
    },
    {
      name: 'Itanhaém - SP',
      identity: 'itanhaemsp',
      connection: 'ItanhaemSP',
    },
    {
      name: 'Demopoa - RS',
      identity: 'demopoars',
      connection: 'DemopoaRS',
    },
    {
      name: 'Corupá - SC',
      identity: 'corupasc',
      connection: 'CorupaSC',
    },
    {
      name: 'Guaíra - SP',
      identity: 'guairasp',
      connection: 'GuairaSP',
    },
    {
      name: 'Marau - RS',
      identity: 'maraurs',
      connection: 'MarauRS',
    },
    {
      name: 'Agronômica - SC',
      identity: 'agronomicasc',
      connection: 'AgronomicaSC',
    },
    {
      name: 'Santa Rita Do Passa Quatro - SP',
      identity: 'santaritadopassaquatrosp',
      connection: 'SantaRitaDoPassaQuatroSP',
    },
    {
      name: 'Câmara - AD',
      identity: 'camaraad',
      connection: 'CamaraAD',
    },
    {
      name: 'São Francisco Do Sul - SC',
      identity: 'saofranciscodosulsc',
      connection: 'SaoFranciscoDoSulSC',
    },
    {
      name: 'Veranópolis - RS',
      identity: 'veranopolisrs',
      connection: 'VeranopolisRS',
    },
    {
      name: 'Canaã dos Carajás - PA',
      identity: 'canaadoscarajaspa',
      connection: 'CanaaDosCarajasPA',
    },
    {
      name: 'Ponte Nova - MG',
      identity: 'pontenovamg',
      connection: 'PonteNovaMG',
    },
    {
      name: 'Chapecó DGP - SC',
      identity: 'chapeco-dgpsc',
      connection: 'ChapecoDGPSC',
    },
    {
      name: 'Demo P - SP',
      identity: 'demopsp',
      connection: 'DemoPSP',
    },
    {
      name: 'Demo Goiânia - GO',
      identity: 'demogoianiago',
      connection: 'DemoGoianiaGO',
    },
    {
      name: 'Blumenau - SC',
      identity: 'blumenausc',
      connection: 'BlumenauSC',
      loginUrl:
        'https://processodigital.blumenau.sc.gov.br/i4iFlowPMB/login.aspx?0,APROVADIGITAL',
    },
    {
      name: 'POC1 - AD',
      identity: 'poc1ad',
      connection: 'POC1AD',
    },
    {
      name: 'POC2 - AD',
      identity: 'poc2ad',
      connection: 'POC2AD',
    },
    {
      name: 'POC3 - AD',
      identity: 'poc3ad',
      connection: 'POC3AD',
    },
    {
      name: 'Piratininga - SP',
      identity: 'piratiningasp',
      connection: 'PiratiningaSP',
    },
    {
      name: 'Barbacena - MG',
      identity: 'barbacenamg',
      connection: 'BarbacenaMG',
    },
    {
      name: 'Cambuí - MG',
      identity: 'cambuimg',
      connection: 'CambuiMG',
    },
    {
      name: 'Carmo Do Paranaíba - MG',
      identity: 'carmodoparanaibamg',
      connection: 'CarmoDoParanaibaMG',
    },
    {
      name: 'Piranga - MG',
      identity: 'pirangamg',
      connection: 'PirangaMG',
    },
    {
      name: 'Itaquaquecetuba - SP',
      identity: 'itaquaquecetubasp',
      connection: 'ItaquaquecetubaSP',
    },
    {
      name: 'Xaxim - SC',
      identity: 'xaximsc',
      connection: 'XaximSC',
    },
    {
      name: 'Cruz Alta - RS',
      identity: 'cruzaltars',
      connection: 'CruzAltaRS',
    },
    {
      name: 'Três Coracões - MG',
      identity: 'trescoracoesmg',
      connection: 'TresCoracoesMG',
    },
    {
      name: 'São João do Triunfo - PR',
      identity: 'saojoaodotriunfopr',
      connection: 'SaoJoaoDoTriunfoPR',
    },
    {
      name: 'Tatuí - SP',
      identity: 'tatuisp',
      connection: 'TatuiSP',
    },
    {
      name: 'Medianeira - PR',
      identity: 'medianeirapr',
      connection: 'MedianeiraPR',
    },
    {
      name: 'Produto - AD',
      identity: 'produtoad',
      connection: 'ProdutoAD',
    },
    {
      name: 'Votorantim - SP',
      identity: 'votorantimsp',
      connection: 'VotorantimSP',
    },
    {
      name: 'Frederico Westphalen - RS',
      identity: 'fredericowestphalenrs',
      connection: 'FredericoWestphalenRS',
    },
    {
      name: 'Balneário Camboriú - SC',
      identity: 'balneariocamboriusc',
      connection: 'BalnearioCamboriuSC',
    },
    {
      name: 'Demo Cliente - AD',
      identity: 'democlientead',
      connection: 'DemoClienteAD',
    },
    {
      name: 'Oportunidades - AD',
      identity: 'oportunidadesad',
      connection: 'OportunidadesAD',
    },
    {
      name: 'São Miguel do Oeste - SC',
      identity: 'saomigueldooestesc',
      connection: 'SaoMiguelDoOesteSC',
    },
    {
      name: 'Matão - SP',
      identity: 'mataosp',
      connection: 'MataoSP',
    },
    {
      name: 'Pacatuba - CE',
      identity: 'pacatubace',
      connection: 'PacatubaCE',
    },
    {
      name: 'Ibirubá - RS',
      identity: 'ibirubars',
      connection: 'IbirubaRS',
    },
    {
      name: 'Venâncio Aires - RS',
      identity: 'venancioairesrs',
      connection: 'VenancioAiresRS',
    },
    {
      name: 'Sao José dos Pinhais - PR',
      identity: 'saojosedospinhaispr',
      connection: 'SaoJoseDosPinhaisPR',
    },
    {
      name: 'Montes Claros - MG',
      identity: 'montesclarosmg',
      connection: 'MontesClarosMG',
    },
    {
      name: 'Mogi Guaçu - SP',
      identity: 'mogiguacusp',
      connection: 'MogiGuacuSP',
    },
    {
      name: 'Gravatá - PE',
      identity: 'gravatape',
      connection: 'GravataPE',
    },
    {
      name: 'Inema - BA',
      identity: 'inemabaad',
      connection: 'InemabaAD',
    },
    {
      name: 'BB - AD',
      identity: 'bbad',
      connection: 'BBAD',
    },
    {
      name: 'Cambé - PR',
      identity: 'cambepr',
      connection: 'CambePR',
    },
    {
      name: 'Ariquemes - RO',
      identity: 'ariquemesro',
      connection: 'AriquemesRO',
    },
    {
      name: 'Londrina - PR',
      identity: 'londrinapr',
      connection: 'LondrinaPR',
    },
    {
      name: 'Cohab Campinas - SP',
      identity: 'cohabcampinassp',
      connection: 'CohabCampinasSP',
    },
    {
      name: 'Santiago - RS',
      identity: 'santiagors',
      connection: 'SantiagoRS',
    },
    {
      name: 'CimviVS - SC',
      identity: 'consorciocimvisc',
      connection: 'ConsorcioCimviSC',
    },
  ];

  authenticationUrl = {
    v1: 'https://api.producao.aprova.com.br/authentication-api/v1',
  };

  citiesUrl = {
    v1: 'https://api.producao.aprova.com.br/cities',
  };

  usersUrl = {
    v2: 'https://api.producao.aprova.com.br/users/v2',
  };

  recaptcha = {
    siteKey: '6Les3sEUAAAAAEw59z-wAQOt8no3d500fFYdbLf0',
  };

  webpki = {
    apiKey:
      'AiEAKi5hcHJvdmEuY29tLmJyLCoucHJlZmVpdHVyYXMubmV0QwBpcDQ6MTAuMC4wLjAvOCxpcDQ6MTI3LjAuMC4wLzgsaXA0OjE3Mi4xNi4wLjAvMTIsaXA0OjE5Mi4xNjguMC4wLzE2AwBQcm8AAAABOllvzNrFPUw4wm6rxLyvjD29Kx91wsF0BZRnAM52cdw23Sbn9VkenhbceiHRK+rXfVeQOKZXy0Rsq5sK47CAdSyfOYyV3YomgHeYTN3GT6uzhCcG+QX00HVFrh4aajLbp3eC74Z/HK8kAb9j0gFRM7SiEtRWiKFMNg5fQkmT6LoeBP//inKwePScYJfc3srB2Txycbvli6I02MSBOmwLNERA0xXYnJDte4eT5wT96YHBS5HOIfURRl0tw7DbC/TLqJRQVPxruIjuafbnk5/uMKoni2oHP8Ftk/kpcuts3WryaB9DN6hinbEhSCoH5E8D+j9fxGVb5A4ZYz/KM5Vqxw==',
    pluginUrl:
      'https://chrome.google.com/webstore/detail/web-pki/dcngeagmmhegagicpcmpinaoklddcgon',
  };

  mdChatClient = '724E7555A44B47A595657B00F34892D0';

  socialLogin = {
    google: {
      clientId:
        '151302971136-2qvflstuuqpe3j2f865bsrgh7cvjev02.apps.googleusercontent.com',
    },
  };
}
